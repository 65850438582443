.navigation {
  padding: 50px !important;
  color: #7f8089;
}

.navigation nav {
  float: right;
  margin-top: 15px;
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #2f3037;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5px 20px;
}

.nav-link.is-active {
  color: #9798a1;
}

.nav-link.is-active:hover {
  opacity: 1;
}

.logo {
  width: 243px;
  height: 56px;
}

@media only screen and (max-width: 600px) {
  .nav-menu-container {
    display: block;
  }

  .nav-menu-container > div {
    display: none;
  }

  .nav-menu-container > a {
    padding-bottom: 10px;
  }
}
