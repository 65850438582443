
.app-store {
  display: block;
  margin-top: 40px;
  background: transparent url("./btn-app-store.png") left top no-repeat;
  background-size: 165px 56px;
  width: 165px;
  height: 56px;
}

/******************************
 * Saying
 ******************************/
.saying {
  background: #37404f;
  color: #fff;
  padding: 68px;
}

.mac-icon {
  background: transparent url("./surge-mac-icon.png") center center no-repeat;
  background-size: 128px 128px;
  width: 128px;
  height: 128px;
  margin: -132px auto 50px;
}

.mac-icon:hover {
  transform: rotate(360deg);
  transition: all 60s;
}

.saying h2 {
  text-align: center;
  font-weight: normal;
  font-size: 36px;
  margin-bottom: 80px;
}

.remarks {
  margin-bottom: -80px;
}

.remarks .col {
  position: relative;
  padding-left: 72px;
  padding-right: 50px;
  margin-bottom: 80px;
}

.remarks img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 99px;
  width: 60px;
  height: 60px;
}

.remarks p {
  margin: 0 0 10px;
}

.remarks .content {
  color: #97a0b0;
}


.promotion-content {
  font-size: 14px;
  color: #657e92;
  line-height: 1.5;
  width: 300px;
  padding-left: 6px;
}


/******************************
 * Preview
 ******************************/
.preview {
  margin-top: 60px;
  min-height: 271px;
  padding: 0;
}

.slogan {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.preview img {
  display: block;
  margin: 40px auto 0;
  width: 998px;
  height: 662px;
}

/******************************
 * Introduction
 ******************************/

.introduction {
  position: relative;
  margin-top: 68px;
  background: transparent url("./introduction-bottom-line.png") center bottom no-repeat;
  background-size: 1440px 76px;
  padding: 0 0 72px;
}

.introduction h2 {
  font-weight: 600;
  font-size: 28px;
}

.introduction .content {
  width: 570px;
  margin-left: 68px;
}

.introduction img {
  position: absolute;
  margin-right: 20px;
  top: 0px;
  right: 0px;
  width: 750px;
  height: 510px;
}

.introduction p {
  color: #7f8089;
  font-size: 18px;
  line-height: 1.6;
}

.introduction p:last-of-type {
  margin-bottom: 28px;
}

.introduction p strong {
  font-weight: 600;
  color: #646672;
}

/******************************
 * Responsive
 ******************************/

@media only screen and (max-width: 1320px) {
  .introduction .content {
    width: 30%;
    margin-left: 68px;
  }
  .introduction img {
    width: 60%;
    height: auto;
  }
  .last-thing .content {
    width: 100%;
  }
  .ios-promotion {
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  .iphone-preview {
    right: 20px;
  }
  .promotion-content {
    width: 100%;
    padding-right: 380px;
  }
}

@media only screen and (max-width: 1100px) {
  .logo {
    display: block;
    margin: 0 auto 50px;
  }
  .navigation nav {
    float: none;
    text-align: center;
  }
  header .download-button {
    display: block;
    margin: 40px auto 0;
    width: 220px;
  }
  .preview {
    margin-top: 80px;
  }
  .preview img {
    width: 96%;
    height: auto;
  }
  .introduction img {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    position: static;
  }
  .introduction .content {
    width: 100%;
    margin: 0;
    padding: 0 68px 0;
  }
}

@media only screen and (max-width: 940px) {
  .introduction img {
    float: none;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 780px) {
  .navigation nav a {
    display: block;
    margin: 0 0 10px;
  }

  .navigation nav .download-button {
    margin: 20px auto 0;
  }
  .introduction {
    margin-top: 40px;
  }
  .introduction h2 {
    font-size: 24px;
    text-align: center;
  }
  .slogan {
    padding: 0 20px;
    font-size: 28px;
  }
  .preview {
    margin-top: 20px;
  }
  .introduction .content {
    width: 100%;
    margin: 0;
    padding: 0 20px 0;
  }
  .mac-icon {
    margin-top: -82px;
  }

  .app-store {
    margin: 30px auto 10px;
  }
  .iphone-preview {
    display: none;
  }
  .promotion-content {
    padding-right: 0;
  }
  .modal .modal-wrap {
    width: 96%;
  }
  .l-modal-container {
    margin-top: 120px;
  }
  .modal .description {
    width: 96% !important;
  }

  .package-list a {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .buy-now {
    padding: 30px;
  }
  .buy-now h2 {
    font-size: 28px;
  }
  .content-table {
    position: static;
    float: none;
    width: 100%;
    overflow: hidden;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 980px) {
  .saying .remarks .col {
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .slogan {
    font-size: 24px;
  }

  .saying {
    padding: 50px 20px;
  }

  .row.features {
    padding: 50px 20px;
  }

  .row.features h3 {
    font-size: 20px;
  }

  .saying > h2 {
    font-size: 28px;
  }
}
